import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Network } from '@capacitor/network';

@Component({
  selector: 'app-network-status',
  templateUrl: './network-status.component.html',
  styleUrls: ['./network-status.component.scss']
})
export class NetworkStatusComponent implements OnInit, OnDestroy {
  isOnline: boolean = true;
  isSlowConnection: boolean = false;
  private networkListener: any;
  constructor(public zone: NgZone) {

  }
  async ngOnInit() {
    await this.initializeNetworkListener();
  }

  ngOnDestroy() {
    if (this.networkListener) {
      this.networkListener.remove();
    }
  }

  async initializeNetworkListener() {

    const status = await Network.getStatus();
    this.isOnline = status.connected;

    // Listen for network status changes
    this.networkListener = Network.addListener('networkStatusChange', (status) => {
      this.zone.run(() => {
        this.isOnline = status.connected;
        this.checkForSlowNetwork();  
      });      
    });

    // Initial slow network check
    this.checkForSlowNetwork();
  }

  async checkForSlowNetwork() {
    if (!this.isOnline) {
      this.isSlowConnection = false;
      return;
    }

    // Measure response time for network speed
    const startTime = Date.now();
    try {
      await fetch('https://www.google.com/favicon.ico', { mode: 'no-cors' });
      const duration = Date.now() - startTime;

      // Set threshold (e.g., 2000 ms)
      this.isSlowConnection = duration > 2000;
    } catch {
      this.isSlowConnection = true;
    }
  }
}