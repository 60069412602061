import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../app/guard/auth.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home'
    , canActivate: [AuthGuard]
    , loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./kontakt/kontakt.module').then( m => m.KontaktPageModule)
  },
  {
    path: 'konto',
    loadChildren: () => import('./auth/konto/konto.module').then( m => m.KontoPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registrieren',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'kontrollen/selectpp',
    canActivate: [AuthGuard],
    loadChildren: () => import('./kontrollen/selectpp/selectpp.module').then( m => m.SelectppPageModule)
  },
  {
    path: 'kontrollen/create/:grundid',
    canActivate: [AuthGuard],
    loadChildren: () => import('./kontrollen/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'ue/create',
    canActivate: [AuthGuard],
    loadChildren: () => import('./ue/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'ue/edit/:busid',
    canActivate: [AuthGuard],
    loadChildren: () => import('./ue/edit/edit.module').then( m => m.EditPageModule)
  },
  {
    path: 'ue/list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./ue/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'kontrollen/check',
    canActivate: [AuthGuard],
    loadChildren: () => import('./kontrollen/check/check.module').then( m => m.CheckPageModule)
  },
  {
    path: 'newregistration',
    loadChildren: () => import('./newregistration/newregistration.module').then( m => m.NewregistrationPageModule)
  },
  {
    path: 'hilfe',
    loadChildren: () => import('./hilfe/hilfe.module').then( m => m.HilfePageModule)
  },
  {
    path: 'ea/list',
    loadChildren: () => import('./employeeavailabilit/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'ep/list',
    loadChildren: () => import('./einsatzplan/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'ep/detail',
    loadChildren: () => import('./einsatzplan/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
