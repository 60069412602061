// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.network-status {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
  color: white;
  font-size: 14px;
  z-index: 1000;
}
.network-status.offline {
  background-color: var(--ion-color-danger);
}
.network-status.slow {
  background-color: var(--ion-color-warning);
}
.network-status ion-icon {
  margin-right: 5px;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/app/network-status/network-status.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;AACJ;AACI;EACE,yCAAA;AACN;AAEI;EACE,0CAAA;AAAN;AAGI;EACE,iBAAA;EACA,sBAAA;AADN","sourcesContent":[".network-status {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    padding: 10px;\n    text-align: center;\n    color: white;\n    font-size: 14px;\n    z-index: 1000;\n  \n    &.offline {\n      background-color: var(--ion-color-danger);\n    }\n  \n    &.slow {\n      background-color: var(--ion-color-warning);\n    }\n  \n    ion-icon {\n      margin-right: 5px;\n      vertical-align: middle;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
