import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Import the AngularFire Module
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor} from './services/token.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import localeDe from '@angular/common/locales/de';
import localeDECH from '@angular/common/locales/de-CH';
import { registerLocaleData } from '@angular/common';
import {LOCALE_ID} from '@angular/core';

import { NetworkStatusComponent } from './network-status/network-status.component';


registerLocaleData(localeDECH);
@NgModule({
  declarations: [AppComponent, NetworkStatusComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule
    , AngularFireModule.initializeApp(environment.firebaseConfig)
    , AngularFireAuthModule
    , AngularFirestoreModule
    , HttpClientModule
    , ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' })
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ,{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  , {provide: LOCALE_ID, useValue: 'de-CH'}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
